.Name {
    width: 100%;
    height: 100vh;
    background: white;

    
    .title {
        width: 400px;
        font-size: 30px;
        font-weight: 600;
        color: black;
        text-align: left;
        margin: 10px auto;
        margin-top: 60px;

        span{
            
            background: -webkit-linear-gradient(286deg, #ff4d00, #ff01eb);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    
    .subtitle {
        width: 400px;
        font-size: 15px;
        font-weight: 400;
        color: #041527;
        margin: 10px auto;
        margin-top: 5px;
        text-align: left;
    }


        
    input {
        width: 400px;
        font-size: 15px;
        font-weight: 500;
        padding: 15px 15px;
        background: white;
        color: black;
        border: 1px solid #000000;
        border-radius: 3px;
        cursor: pointer;
        margin: 20px auto;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    button {
        width: 400px;
        font-size: 15px;
        font-weight: 500;
        padding: 15px 15px;
        background: #FF4D00;
        color: white;
        border-radius: 3px;
        cursor: pointer;
        margin: 20px auto;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    button:active{
        transform: scale(0.95);
    }

    .privacy {
        width: 400px;
        font-size: 12px;
        font-weight: 400;
        color: #041527;
        margin: 10px auto;
        margin-top: 25px;
    }


    @media screen and (max-width: 400px) {
        .title {
            width: 300px;
        }

        .subtitle {
            width: 300px;
        }

        input {
            width: 300px;
        }
        button {
            width: 300px;
        }
        .privacy{
            width: 300px;
        }

    }

    
    @media screen and (max-width: 300px) {
        .title {
            width: 250px;
        }

        .subtitle {
            width: 250px;
        }

        input {
            width: 250px;
        }
        button {
            width: 250px;
        }
        .privacy{
            width: 250px;
        }

    }


}
