.LandingPage {
.RightText1 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 80px 40px;

    .text {
        width: 40%;

        .title {
            font-size: 40px;
            font-weight: bold;
            color: #041527;
        }
        
        .subtitle {
            font-size: 20px;
            font-weight: 400;
            color: #041527;
            margin-top: 10px;
        }
        
    }

    img {
        width: 600px;
        border-radius: 20px;
    }

    @media screen and (max-width: 1233px) {
        img {
            width: 550px;
            border-radius: 20px;
        }

    }

    @media screen and (max-width: 900px) {

        img {
            width: 350px;
            border-radius: 20px;
        }

    }

    

@media screen and (max-width: 800px) {

    .text {
        width: 100%;
           
        .title {
            font-size: 30px;
        }
        
        .subtitle {
            font-size: 15px;
        }

    }

    img {
        border-radius: 5px;
        width: 100%;
        margin-top: 20px;
    }

}
    
}




@media screen and (max-width: 800px) {

    .RightText1 {
        display: block;
    }

}

}
