
.LandingPage {
    
    .LastCall{
        
    .circle {
        width: 100%;
        height: 500px;
        overflow: hidden;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
        margin-top: -260px;
        background: linear-gradient(0deg, #ff4d003b, white);

       
    }
    
  

    .lastform {
        width: 60%;
        background: #4A004B;
        border-radius: 20px;
        margin: 10px auto;
        margin-top: -150px;
        text-align: center;
        padding: 80px 0px;

          
    .title {
        font-size: 40px;
        font-weight: 500;
        color: white;
        margin-bottom: 15px
    }
    
    button {
        font-size: 15px;
        font-weight: 600;
        padding: 8px 20px;
        margin-left: 10px;
        border: none;
        background: white;
        color: #FF4D00;
        border-radius: 5px;
        cursor: pointer;
    }


    @media screen and (max-width: 500px) {


        .title {
            font-size: 25px;
        }
    
    }

    button:hover {
        opacity: 0.85;
    }

    button:active {
        transform: scale(0.95);
    }
    

    }

    @media screen and (max-width: 500px) {

        .circle {
            width: 100%;
            height: 300px;
            margin-top: -120px;
           
        }

        .lastform {
            width: 90% !important;
            margin-top: -120px !important;
            padding: 40px 0px;
        }
    
    }

    }

    


}

