
.DropdownMenuContent {
  min-width: 220px;
  background-color: #1a1a1a;
  color: white;
  border-radius: 3px;
  padding: 5px;
  margin-right: 20px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}



.DropdownMenuItem{
  font-size: 15px;
  font-weight: 400;
    color: white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 5px;
    position: relative;
    padding-left: 10px;
    -webkit-user-select: none;
    user-select: none;
    outline: none;
}

.DropdownMenuItem svg{
  font-size: 14px;
  margin-right: 10px;
  color: #a1a1a1;
}

.DropdownMenuItem span {
  font-size: 10px;
  padding: 3px 8px;
  background: #0d99ff24;
  color: #0d99ff;
  border-radius: 10px;
  margin-left: auto;
}

.DropdownMenuItem[data-highlighted] {
  background-color: #0d99ff;
  color: white;
}

.DropdownMenuItem[data-highlighted] svg{
  color: white;
}

.DropdownMenuItem[data-highlighted] span{
  background-color: white;
}


@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
