.Signup, .Name {


    .Nav {
        height: 65px;
        background: white;
        border-bottom: 1px solid #EDEDED;
        box-shadow: 0px 1px 20px 0px #ebebeb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 25px;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;

        .Logo {
            font-size: 25px;
            font-weight: 600;
            color: #041527;
        }

    }


}