
.ContextMenuContent {
    min-width: 220px;
    background-color: #1a1a1a;
    color: white;
    border-radius: 3px;
    padding: 5px;
    margin-right: 20px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}

.ContextMenuItem{
    font-size: 15px;
    font-weight: 400;
      color: white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      height: 35px;
      padding: 0 5px;
      position: relative;
      padding-left: 10px;
      -webkit-user-select: none;
      user-select: none;
      outline: none;
}

.ContextMenuItem[data-highlighted],.ContextMenuItem:hover {
    background-color: #0d99ff;
    color: white;
}

.ContextMenuSeparator {
    height: 1px;
    background-color: #4a4a4a;
    margin: 5px;
}
