@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.Site {
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .nav {
        padding: 0px 15px;
        height: 60px;
        background: linear-gradient(90deg, #FF4D00, #df00ad);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navleft {
            display: flex;
            align-items: center;

            
        .logo {
            font-size: 20px;
            font-weight: 600;
            color: white;
        }

        input {
            margin-left: 20px;
            font-size: 15px;
            font-weight: 400;
            padding: 5px 10px;
            border-radius: 5px;
            background: none;
            color: white;
            border: 1px solid transparent;
            width: fit-content;
            max-width: 300px;
            outline: none;
        }
        input:focus{
            border: 1px solid white;
            background: #ffffff2e;
        }
        }

        .navcenter {
            display: flex;
            align-items: center;

            
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 5px;
                background: none;
            font-size: 25px;
            color: white;
            margin: 0;
            }

            button:hover{
                background: #ffffff50;
            }
            button:active{
                transform: scale(0.95);
            }
        }

        .navright {
            display: flex;
            align-items: center;

            button {
                margin: 0px 5px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                min-width: 35px;
                height: 35px;
                border-radius: 5px;
                font-size: 15px;
                font-weight: 500;
            }

            button:active{
                transform: scale(0.95);
            }
            
        }

        .navright button:nth-child(1),.navright button:nth-child(2){
            width: 35px;
            padding: unset;
            background: none;
            font-size: 20px;
            color: white;
            margin: 0;
        }

        .navright button:nth-child(1):hover,.navright button:nth-child(2):hover,.navright button:nth-child(3):hover{
            background: #ffffff50;
        }
        .navright button:nth-child(4):hover{
            background: #ffffffe8;
        }

        .navright button:nth-child(3){
            background: none;
            border: 2px solid white;
            color: white;
        }

        .navright button:nth-child(4) svg,.navright button:nth-child(3) svg{
            margin-right: 10px;
        }


    }

    .editor {
        display: flex;

        .modules {
            width: 80px;
            height: calc(100vh - 60px);
            background: #292929;

            button {
                display: block;
                width: 65px;
                height: 65px;
                margin: 15px auto;
                border-radius: 5px;
                font-size: 10px;
                color: white;
                background: none;
                text-align: center;

                svg {
                    font-size: 25px;
                    margin: 5px auto;
                    color: white;
                }

            }

            button:hover{
                background: #3e3e3e;
            }
            button:active{
                transform: scale(0.9);
            }

        }

        .modulesslider {
            width: 300px;
            height: calc(100vh - 60px);
            background: #323232;
            overflow-y: auto;
            
          
        }

        .canvas {
            width: calc(100% - 380px);
            height: calc(100vh - 60px);
            background: #fff4f0;
        }

    }
}