.LandingPage {

    .Testimonials {
        .MainLine {
            font-size: 60px;
            font-weight: 700;
            text-align: center;
            margin-top: 100px;
        }

        .SubLine {
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            margin-top: 10px;
        }


        .marquee {
            margin-top: 50px;
            padding-bottom: 60px;
            border-bottom: 1px solid #EDEDED;

            .tweet {
                width: 300px;
                border: 1px solid #dfdfdf;
                display: block;
                border-radius: 10px;
                padding: 10px;
                height: fit-content;


                .top {
                    display: flex;
                    align-items: start;
                    justify-content: space-between;


                    .left {
                        display: flex;
                        align-items: center;

                        img {
                            height: 50px;
                            border-radius: 100%;
                        }

                        .info {
                            display: block;
                            text-align: left;
                            margin-left: 10px;


                            .name {
                                font-size: 15px;
                                font-weight: 500;
                            }

                            .username {
                                font-size: 13px;
                                font-weight: 400;
                                margin-top: -3px;
                                color: #556372;
                            }
                        }

                    }



                    .right img {
                        height: 25px;
                    }

                }


                .msg {
                    display: block;
                    text-align: left;
                    margin-top: 10px;
                    font-size: 13px;
                }

            }

        }


        @media screen and (max-width: 768px) {
            .MainLine {
                font-size: 50px;
            }

            .SubLine {
                font-size: 18px;
            }
        }

        @media screen and (max-width: 425px) {
            .MainLine {
                font-size: 30px;
                padding: 0px 20px;
            }

            .SubLine {
                font-size: 15px;
                padding: 0px 20px;
            }


        }

    }
}