@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.Profile{
    font-family: "Inter", sans-serif;
    button,input,select{
        font-family: "Inter", sans-serif;
    }

    .title {
        font-size: 18px;
        font-weight: 500;
        margin: 30px;
    }


         
   

    .name {
        width: 400px;
        margin-left: 35px;

        input {
            width: 400px;
            font-size: 15px;
            font-weight: 500;
            padding: 15px 15px;
            background: white;
            color: black;
            border: 1px solid #000000;
            border-radius: 3px;
            cursor: pointer;
            margin: 20px auto;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: start;
        }
    
    
        button{
            width: 400px;
            font-size: 15px;
            font-weight: 500;
            padding: 15px 15px;
            background: white;
            color: black;
            border: 1px solid #000000;
            border-radius: 3px;
            cursor: pointer;
            margin: 20px auto;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: start;
        }
        
    }
    
    .name input:nth-child(1){
        opacity: 0.6;
    }

    .name button:nth-child(3){
        background: #0d99ff;
        color: white;
        border: none;
    }

    .name button:nth-child(4){
        background: #FF4D00;
        color: white;
        border: none;
    }
    

   
    
}