.LandingPage{

    .TrendingTemplates{
        padding: 20px 20px;
        background: #f3f3f3;
        margin-top: 50px;
        .Title {
            font-size: 20px;
            font-weight: 500;
        }

        .templateswrap {
            overflow-x: auto;
            .templates {
                width: fit-content;
                display: flex;
                align-items: center;
                margin-top: 20px;
                .templates {
                    width: 200px;
                    height: 300px;
                    border-radius: 10px;
                    margin-right: 20px;
                    border: 1px solid #202020;
                }
            }
        }
     
        
       
    }
   
}